import Splide from '@splidejs/splide';

function splideInit() {

    var elms = document.getElementsByClassName( 'lawyer-slider' );
    for ( var i = 0; i < elms.length; i++ ) {
        var splide = new Splide( '.splide.lawyer-slider', {
            type   : 'loop',
            perPage: 4,
            perMove: 1,
            pagination: false,
            gap: '15px',

            breakpoints: {
                576: {
                    perPage: 1,
                },
                768: {
                    perPage: 2,
                },
                992: {
                    perPage: 3,
                }
            }
        } );
        splide.mount();
    }

    var elms = document.getElementsByClassName( 'product-slider' );
    for ( var i = 0; i < elms.length; i++ ) {
        var splide = new Splide( '.splide.product-slider', {
            type   : 'loop',
            perPage: 4,
            perMove: 1,
            pagination: true,
            gap: '15px',
            fixedWidth : '26vw',
            padding : { 'left' : '13vw' },
            arrows: true,

            breakpoints: {
                576: {
                    perPage: 1,
                    fixedWidth : '100vw',
                    padding : { 'left' : '0' },
                    gap: '0',
                },
                768: {
                    perPage: 2,
                    fixedWidth : '50vw',
                },
                992: {
                    perPage: 3,
                    fixedWidth : '33vw',
                }
            }
        } );
        splide.mount();
    }

    var elms = document.getElementsByClassName( 'video-slider' );
    for ( var i = 0; i < elms.length; i++ ) {
        var splide = new Splide( '.splide.video-slider', {
            type   : 'slide',
            perPage: 4,
            perMove: 1,
            pagination: false,
            gap: '15px',

            breakpoints: {
                576: {
                    padding : { 'left' : '10vw' },
                    perPage: 1,
                    gap: '10px',
                    fixedWidth : '60vw',
                    arrows: false,
                    pagination: true,
                },
                768: {
                    perPage: 2,
                    padding : { 'left' : '0' },
                    gap: '15px',
                },
                992: {
                    perPage: 3,
                }
            }
        } );
        splide.mount();
    }

}

document.addEventListener('DOMContentLoaded', async () => {

    splideInit();

});


