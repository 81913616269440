window.addEventListener("DOMContentLoaded", (evt) => {

    if(localStorage.getItem('cookiePreferences') === null) {
        localStorage.setItem('cookiePreferences', '');
    }

    const cookieAlertWrapper = new bootstrap.Alert("#cookieAlertWrapper");
    const cookieAlert = new bootstrap.Alert("#cookieAlert");
    const cookieModal = new bootstrap.Modal("#cookieModal", {});
    const checkBoxes = document.getElementsByName("cookiePreference");
    const saveButtons = document.querySelectorAll('.save-cookies');

    function setCheckboxFromLocalstorage() {
        if (localStorage.getItem("cookieConsent") && localStorage.getItem("cookiePreferences")) {
            const storedValues = localStorage.getItem("cookiePreferences").split(",");
            checkBoxes.forEach((el) => {
                if (storedValues.includes(el.value)) {
                    el.checked = true;
                }
            });
        } else {
            // set default values true for cookie consent
            checkBoxes.forEach((el) => {
                if (el.value == "marketing") {
                    el.checked = false;
                } else {
                    el.checked = true;
                }
            });
        }
    }

    function getCookiePreferences() {
        const selectedCboxes = Array.prototype.slice.call(checkBoxes).filter((cb) => cb.checked == true);
        const selectedValues = [];
        selectedCboxes.forEach((e) => {
            selectedValues.push(e.value);
        });
        return selectedValues;
    }

    function activateScripts(buttonScope = false) {
        const storedValues = localStorage.getItem("cookiePreferences").split(",");
        storedValues.forEach((category) => {

            const cookieScripts = document.querySelectorAll(`[data-cookietype="${ category }"]`);
            cookieScripts.forEach((script) => {
                if (script.dataset.type !== script.type) {
                    const newNode = document.createElement(script.tagName);
                    newNode.innerHTML = script.innerHTML;
                    for (let i = 0, max = script.attributes.length - 1; i <= max; ++i) {
                        newNode.setAttribute(script.attributes[i].name,script.attributes[i].value);
                    }
                    newNode.setAttribute('type', script.dataset.type);
                    script.parentNode.insertBefore(newNode, script);
                    script.parentNode.removeChild(script);
                }
            });

            if (category == "statistics") {
                // podcasts
                const podcasts = document.querySelectorAll('.publications__card-audio script');
                podcasts.forEach((podcast) => {
                    const src = podcast.getAttribute("data-src");
                    podcast.src = src;
                });
                const textsPodcastCookies = document.querySelectorAll('.textPodcastCookie');
                textsPodcastCookies.forEach((el) => {
                    el.classList.add('d-none');
                });

            }

            // youtube
            const iframesYoutube = document.querySelectorAll('.iframeYoutube');
            iframesYoutube.forEach((el) => {
                const src = el.getAttribute("data-src");
                el.src = src;
                el.classList.remove('d-none');
            });
            const textYoutubeCookies = document.querySelectorAll('.textYoutubeCookie');
            textYoutubeCookies.forEach((el) => {
                el.classList.add('d-none');
            });
            
        });
        if (buttonScope) {
            document.querySelector('#cookieAlert').classList.add('d-none');
            document.getElementById("cookieAlertWrapper").classList.remove("active");
            cookieModal.hide();
        }
    }

    function saveCookies() {
        localStorage.setItem("cookieConsent", true);
        localStorage.setItem("cookiePreferences", getCookiePreferences());

        activateScripts(true);
    }

    setCheckboxFromLocalstorage();
    activateScripts();

    saveButtons.forEach(el => { el.addEventListener('click', () => { saveCookies() }) });
    
    if (localStorage.getItem("cookieConsent") === null) {
        document.getElementById("cookieAlert").classList.remove("d-none");
        document.getElementById("cookieAlertWrapper").classList.add("active");
    }
});
