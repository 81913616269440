document.addEventListener('DOMContentLoaded', async () => {
    // Load CSRF cookie
    const response = await fetch('/api/csrf');
    if (!response.ok) {
        const message = `An error has occurred when retrieving the CSRF token: ${response.status}`;
        throw new Error(message);
    }

    // Get CSRF cookie as JSON and save it as global variable
    const csrf = await response.json();
    window.csrfTokenName = csrf.csrfTokenName;
    window.csrfTokenValue = csrf.csrfTokenValue;
});


/* 

FORMIE CSRF TOKEN REFRESH: https://verbb.io/craft-plugins/formie/docs/template-guides/cached-forms

document.addEventListener('DOMContentLoaded', (event) => {
    // Fetch the form we want to deal with
    let $form = document.querySelector('#{{ form.formId }}');

    // Find the CSRF token hidden input, so we can replace it
    let $csrfInput = $form.querySelector('input[name="CRAFT_CSRF_TOKEN"]');

    // Fetch the new token for the form and replace the CSRF input with our new one
    fetch('/actions/formie/forms/refresh-tokens?form={{ form.handle }}')
        .then(result => { return result.json(); })
        .then(result => { $csrfInput.outerHTML = result.csrf.input; });
});
*/