// Importing scripts
import * as bootstrap from 'bootstrap';

import './js/csrf';
import './js/scripts';
import './js/splideslider';
//import './js/filter';
import './js/filterbutton';
import './js/cookie-consent';

// Importing styles (bootstrap + customization)
//import './scss/bootstrap.scss';
import './scss/styles.scss';

// Default theme
import '@splidejs/splide/css';

// Accept HMR
if (module && module.hot) {
    module.hot.accept();
}

window.bootstrap = bootstrap;
