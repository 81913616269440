document.addEventListener('DOMContentLoaded', async () => {

    document.querySelectorAll('.component-circleMenu-single').forEach(el => {
        el.addEventListener('click', event => {
            document.querySelectorAll('.component-circleMenu-single').forEach(inner => {
                if (inner != el) {
                    inner.classList.remove('active');
                }
            })
            el.classList.remove('inactive');
            el.classList.toggle('active');

            if (el.classList.contains('active') == true) {
                document.querySelectorAll('.component-circleMenu-single').forEach(inner => {
                    if (inner != el) {
                        inner.classList.add('inactive');
                    }
                })
            } else {
                document.querySelectorAll('.component-circleMenu-single').forEach(inner => {
                    inner.classList.remove('inactive');
                })
            }

            el.scrollIntoView({behavior: "smooth"});

        })
    })

});
