const debug = 0;

document.addEventListener('DOMContentLoaded', async () => {

    const body = document.body;

    document.addEventListener('click', function handleClickOutsideBox(event) {

        if (event.target.classList.value.indexOf("filterbutton") == -1 && event.target.classList.value.indexOf("dropdown-input") == -1) {
            document.querySelectorAll('.filterbutton.isActive').forEach(el => {
                el.classList.remove('isActive');
            });
        }

    });

    function setFilterButtons(){
        if (debug > 0) console.log("function setFilterButtons() ");

        document.querySelectorAll('[data-action="filterbutton"]').forEach(el => {
            el.addEventListener('click', event => {

                if (debug > 0) console.log("click on filterbutton ");

                var wasActive = el.classList.contains('isActive');
                if (wasActive == true) {
                    el.classList.remove('isActive');
                } else {
                    el.classList.add('isActive');
                }
                document.querySelectorAll('[data-action="filterbutton"]').forEach(el2 => {
                    if (el != el2) {
                        el2.classList.remove('isActive');
                    }
                })
            })
        })
    }

    //    setFilterButtons();

    if (typeof htmx !== 'undefined') {
        htmx.on('htmx:afterOnLoad', function(event) {
  //          setFilterButtons();
        });
    }
    
});
